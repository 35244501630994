import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import layout from "../components/layout/index.vue";
import pageView from "../components/layout/pageView.vue";
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
// export const menuRoutes = [
//     {
//         path: "/firstPage",
//         name: "firstPage",
//         component: () =>
//             import("../views/home.vue"),
//     },
//     {
//         path: "/welcome",
//         name: "welcome",
//         meta: {
//             title: '首页',
//             icon: 'el-icon-s-home'
//         },
//         component: () =>
//             import("../views/Welcome.vue"),
//     }, {
//         path: "/home",
//         name: "home",
//         meta: {
//             title: '报名',
//             icon: 'el-icon-menu'
//         },
//         component: () =>
//             import("../views/HomeView.vue"),
//     },

//     {
//         path: "/orders",
//         name: "orders",
//         meta: {
//             title: '订单列表',
//             icon: 'el-icon-menu'
//         },
//         component: () =>
//             import("../views/Orders.vue"),
//     },
//     {
//         path: "/feedback",
//         name: "feedback",
//         meta: {
//             title: '功能反馈',
//             icon: 'el-icon-headset'
//         },
//         component: () =>
//             import("../views/Feedback.vue"),
//     }, {
//         path: "/products",
//         name: "products",
//         meta: {
//             title: '商品管理',
//             icon: 'el-icon-goods'
//         },
//         component: () =>
//             import("../views/Product.vue"),
//     }, {
//         path: "/store",
//         name: "store",
//         meta: {
//             title: '店铺功能',
//             icon: 'el-icon-shopping-cart-2'
//         },
//         component: pageView,
//         children: [{
//             path: "/store/orders",
//             name: "storeOrders",
//             meta: {
//                 title: '店铺订单',
//                 icon: 'el-icon-s-home'
//             },
//             component: () =>
//                 import("../views/storeOrders.vue"),
//         }]
//     }, {
//         path: "/about",
//         name: "about",
//         meta: {
//             title: '关于我们',
//             icon: 'el-icon-s-home'
//         },
//         component: () =>
//             import("../views/AboutView.vue"),
//     },
//     {
//         path: "/logistics",
//         name: "logistics",
//         meta: {
//             title: '物流管理',
//             icon: 'el-icon-s-home'
//         },
//         component: () =>
//             import("../views/Logistics.vue"),
//     },
//     {
//         path: "/set",
//         name: "set",
//         meta: {
//             title: '设置',
//             icon: 'el-icon-s-home'
//         },
//         component: () =>
//             import("../views/SetUp.vue"),
//     },
//     {
//         path: "/signUp",
//         name: "signUp",
//         meta: {
//             title: '报名管理',
//             icon: 'el-icon-s-home'
//         },
//         component: () =>
//             import("../views/SignUp.vue"),
//     },
//     {
//         path: "/management",
//         name: "management",
//         meta: {
//             title: '管理菜单',
//             icon: 'el-icon-s-home'
//         },
//         component: pageView,
//         children: [{
//             path: "/management/createMenu",
//             name: "createMenu",
//             meta: {
//                 title: '创建菜单',
//                 icon: 'el-icon-s-home'
//             },
//             component: () =>
//                 import("../views/AddManagement.vue"),
//         }, {
//             path: "/management/editMenu",
//             name: "editMenu",
//             meta: {
//                 title: '编辑菜单',
//                 icon: 'el-icon-s-home'
//             },
//             component: () =>
//                 import("../views/EditManagement.vue"),
//         }]
//     },
//     {
//         path: "/system",
//         name: "system",
//         meta: {
//             title: "系统管理",
//             icon: "el-icon-cpu",
//         },
//         component: pageView,
//         children: [{
//             path: "/system/todos",
//             name: "systemTodos",
//             meta: {
//                 title: "系统工具",
//                 icon: "el-icon-s-home",
//             },
//             component: () =>
//                 import("../views/systemTodos.vue"),
//         },
//         {
//             path: "/system/stat",
//             name: "systemStat",
//             meta: {
//                 title: "统计数据",
//                 icon: "el-icon-s-home",
//             },
//             component: () =>
//                 import("../views/systemStat.vue"),
//         },
//         {
//             path: "/system/orgs",
//             name: "systemOrgs",
//             meta: {
//                 title: "组织管理",
//                 icon: "el-icon-s-home",
//             },
//             component: () =>
//                 import("../views/systemOrgs.vue"),
//         }]
//     },
//     {
//         path: "/minapp",
//         name: "minapp",
//         meta: {
//             title: "独立小程序",
//             icon: "el-icon-mobile-phone",
//         },
//         component: pageView,
//         children: [{
//             path: "/minapp/setting",
//             name: "minappSetting",
//             meta: {
//                 title: "小程序设置",
//                 icon: "el-icon-setting",
//             },
//             component: () =>
//                 import("../views/minappSetting.vue"),
//         }]
//     }, {
//         path: "/stat",
//         name: "stat",
//         meta: {
//             title: "数据统计",
//             icon: "el-icon-data-analysis",
//         },
//         component: pageView,
//         children: [{
//             path: "/stat/month",
//             name: "statMonth",
//             meta: {
//                 title: "项目月报",
//                 icon: "el-icon-data",
//             },
//             component: () =>
//                 import("../views/stat/month.vue"),
//         }]
//     },
//     {
//         path: "/fans",
//         name: "fans",
//         meta: {
//             title: "客户管理",
//             icon: "el-icon-user",
//         },
//         component: pageView,
//         children: [{
//             path: "/fans/list",
//             name: "fansList",
//             meta: {
//                 title: "客户列表",
//                 icon: "el-icon-setting",
//             },
//             component: () =>
//                 import("../views/fans/list.vue"),
//         }]
//     }
// ];
export const menuRoutes = [
    {
        path: "/orgStat",
        name: "orgStat",
        component: () => import("../views/orgStat.vue")
    },
    {
        path: "/welcome",
        name: "welcome",
        component: () => import("../views/Welcome.vue"),
    },
    {
        path: "/home",
        name: "home",
        component: () =>
            import("../views/HomeView.vue"),
    },
]
const routes = [{
    path: "/index",
    name: "index",
    component: layout,
    children: menuRoutes
},
{
    path: "/",
    redirect: "/index",
},
{
    path: "/login",
    name: "login",
    component: () =>
        import("../views/login/index.vue"),
},

]

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router