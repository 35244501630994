import Vue from 'vue'
import Vuex from 'vuex'
import { getMenus } from "../api/login";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orgList: [],
    lockList: [
      {
        name: "个人发起",
        type: "personal",
        id: "",
      }
    ],
    menus: [],
    currentCompanyId: "",
    currentHospitalId: "",
    currentShopId: ''
  },
  getters: {
  },
  // 里面定义的方法吗，操作state方法
  mutations: {
    handleOrgList(state, data) {
      let list = []
      data.forEach(element => {
        let obj = {}
        obj = element
        if (element.type == "COMPANY") {
          obj.currentCompanyId = element.id
        } else {
          obj.currentHospitalId = element.id
        }
        list.push(obj)
        state.orgList = [...state.lockList, ...list]
        localStorage.setItem('orglist', JSON.stringify(state.orgList))
      });
    },
    async handleCurrentOrg(state, data) {
      console.log("daya", data);

      if (data !== '') {
        if (data.type == "personal") {
          state.currentCompanyId = ''
          state.currentHospitalId = ''
        } else if (data.type == "COMPANY") {
          state.currentCompanyId = data.id
          localStorage.setItem('currentCompanyId', data.id)
          localStorage.setItem('currentHospitalId', '')
          state.currentHospitalId = ''
        } else {
          state.currentHospitalId = data.id
          state.currentCompanyId = ''
          localStorage.setItem('currentHospitalId', data.id)
          localStorage.setItem('currentCompanyId', '')
        }
        state.currentShopId = data.id
        localStorage.setItem('currentShopId', data.id)
      } else {
        state.currentCompanyId = ''
        state.currentHospitalId = ''
        state.currentShopId = ''
        localStorage.setItem('currentShopId', '')
        localStorage.setItem('currentHospitalId', '')
        localStorage.setItem('currentCompanyId', '')
      }
      // 获取菜单
      const resp3 = await getMenus(state.currentHospitalId, state.currentCompanyId)
      if (resp3.data.code == 200) {
        state.menus = resp3.data.data
        localStorage.setItem('menus', JSON.stringify(state.menus))
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
