<template lang="">
  <div class="xlb_header">
    <div class="logo">
      <img src="../../../assets/logo.png" alt="" />
      <span>小萝卜苗</span>
    </div>
    <div class="xlb_header_con">
      <div class="swich_shop">
        <el-dropdown @command="chooseShop">
          <div class="name">
            {{ currentShopName }}
            <i class="el-icon-caret-bottom"></i>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="item in $store.state.orgList" :command="item.id">{{
                item.name
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="login_info">
        <div class="name" @click="handleLogout">欢迎 {{ name }}，点此登出</div>
        <!--el-dropdown @command="handleLogout">
          <div class="name">{{ name }}</div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { getUserinfo, getOrgs, getMenus } from "../../../api/login";
import moment from "moment";
export default {
  data() {
    return {
      currentShopName: "",
      orgList: [],
      name: "",
      isLogin: false
    };
  },
  async mounted() {
    console.log("header mounted....");
    if (localStorage.getItem("token")) {
      const saveDataTime = localStorage.getItem('expires')
      const isExpires = moment(new Date().getTime()).diff(moment(Number(saveDataTime)), 'days')
      if (isExpires > 7) {
        localStorage.clear()
        this.$message.error('登录信息已过期请重新登录！')
        this.$router.push('login')
      } else {
        // 获取账号信息
        const resp1 = await getUserinfo()
        if (resp1.data.code == 200) {
          const userInfo = {
            avatarUrl: resp1.data.data.avatarUrl,
            nickName: resp1.data.data.nickName,
          };
          this.name = resp1.data.data.nickName
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
        this.initData()

      }
    } else {
      console.log("未登录");
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    ...mapMutations(['handleOrgList', 'handleCurrentOrg']),
    async initData() {
      // 获取组织列表
      const resp2 = await getOrgs()
      if (resp2.data.code == 200) {
        console.log(resp2.data);
        this.handleOrgList(resp2.data.data)

        console.log("!222222222222", localStorage.getItem('currentShopId'));

        if (localStorage.getItem('currentShopId') == null || localStorage.getItem('currentShopId') == '' || localStorage.getItem('currentShopId') == undefined) {
          this.currentShopName = this.$store.state.orgList[0].name
          this.handleCurrentOrg('')
        } else {
          let item = this.$store.state.orgList.filter(element => element.id == localStorage.getItem('currentShopId'))[0]
          console.log("11111111111", item);
          this.currentShopName = item.name
          this.handleCurrentOrg(item)
        }
      }

    },
    chooseShop(value) {
      //设置当前的orgId为选中的orgId。 为避免当前页面内容不刷新造成误解，可以弹出提示，询问切换成功后将重新跳转至首页
      //用户点击确认则重新跳转到home，同时刷新左侧菜单
      this.$confirm("切换发起方会跳转至首页", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("va", value);
          this.$store.state.orgList.forEach((element) => {
            if (value == element.id) {
              this.currentShopName = element.name;
              this.handleCurrentOrg(element)
              this.$store.state.menus = []
              localStorage.setItem('isSwitch','0')
            }
          });
        })
        .catch(() => {
          console.log("取消切换");
        });
    },
    handleLogout() {
      console.log("222222222222");
      this.$confirm("确认登出系统？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        this.$router.push({ path: "/login" });
        window.removeEventListener("setItem", () => { });
      });
    },
  },
};
</script>
<style lang="scss">
.xlb_header {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 180px;
    height: 48px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #272a31;
    border-right: 1px solid #000;
    box-sizing: border-box;

    img {
      height: 60%;
      object-fit: cover;
    }

    span {
      font-size: 16px;
      margin-left: 5px;
    }
  }
}

.xlb_header_con {
  width: calc(100% - 180px);
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;

  .name {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }

  .name:hover {
    color: white;
  }

  .swich_shop {
    cursor: pointer;
  }

  .login_info {
    cursor: pointer;
  }
}
</style>
