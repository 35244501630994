import service from "../utils/request";
export function wxLoginApi(code) {
    return service.get(`/login/webLogin?code=${code}`)
}
export function getUserinfo(params) {
    return service.post('/user/myInfo', params)
}
export function getOrgs(params) {
    return service.post('/user/myOrgs', params)
}
export function getMenus(h_id, c_id) {
    const data = {
        currentHospitalId: h_id,
        currentCompanyId: c_id
    }
    return service.post('/web/menu', data)
}
