<template lang="">
  <el-container style="height: 100vh">
    <el-header><custom-header /></el-header>
    <el-container class="layout_content">
      <el-aside width="180"><custom-menu /></el-aside>
      <el-main>
        <!-- <el-card> -->
        <router-view :key="$route.fullPath" />
        <!-- </el-card> -->
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import customHeader from "./header/header";
import customMenu from "./slider/index.vue";
import moment from "moment";
export default {
  components: {
    customHeader,
    customMenu,
  },
  mounted() {
    console.log("slider/index.vue monted:")
    console.log(this.$store.state)
    if (localStorage.getItem("token")) {
      const saveDataTime = localStorage.getItem('expires')
      const isExpires = moment(new Date().getTime()).diff(moment(Number(saveDataTime)), 'days')
      if (isExpires < 7) {
        this.$router.push("/welcome");
      }
    }
  }
};
</script>
<style lang="scss">
.el-header {
  background-color: #333a42;
  width: 100%;
  color: #e1e5e8;
  padding: 0 !important;
  height: 48px !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  position: relative;
  z-index: 9;
}
.layout_content {
  height: calc(100% - 60px);
  width: 100%;
  .el-main {
    background: #f0f2f5;
    padding: 0;
  }
  .el-aside {
    background-color: #f9fafc;
    border-right: 1px solid #ebebeb;
    height: 100%;
    overflow: hidden;
    width: 180px !important;
    padding-top: 15px;
  }
}
</style>
