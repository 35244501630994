<template>
  <!-- <el-menu default-active="1-4-1" class="menu" :collapse="isCollapse">
    <el-submenu v-for="(submenu, index) in menus" :key="index" :index="index">
      <template slot="title">
        <i :class="submenu.meta.icon"></i>
        <span> {{ submenu.meta.title }}</span>
      </template>
      <template v-for="(menu, index) in submenu.children">
        <el-menu-item :key="index" :index="menu.path">
          <i :class="menu.meta.icon"></i>
          <span>{{ menu.meta.title }}</span>
        </el-menu-item>
      </template>
    </el-submenu>
  </el-menu> -->
  <el-menu :default-active="$route.path" :collapse="isCollapse" @select="chooseMenu">
    <div v-for="(item, index) in $store.state.menus" :key="index" class="aside-menu">
      <el-menu-item :index="item.path" v-if="item.children == '' || item.children == undefined">
        <i :class="item.meta.icon"></i>
        <span>{{ item.meta.title }}</span>
      </el-menu-item>
      <el-submenu v-else-if="item.children" :index="'' + index">
        <template slot="title">
          <i :class="item.meta.icon"></i>
          <span>{{ item.meta.title }}</span>
        </template>
        <el-menu-item v-for="(item_menu, idx) in item.children" :key="idx" :index="item_menu.path">
          <span>{{ item_menu.meta.title }}</span>
        </el-menu-item>
      </el-submenu>
    </div>
  </el-menu>
</template>
<script>
import { getMenus } from "../../../api/login";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      menus: [],
      isCollapse: false,
      orgList: [],
    };
  },
  watch: {
    '$store.state.currentShopId'(ovalue, nvalue) {
      if (localStorage.getItem('isSwitch')=='0') {
        this.$router.push('welcome')
        localStorage.setItem('isSwitch', '1')
      }
    },
  },
  created() {

  },
  async mounted() {
  },
  methods: {
    chooseMenu(index) {
      console.log(index);
      this.$router.push(index);
    },
  },
};
</script>
<style lang="scss">
.el-menu {
  background: transparent !important;
}

.aside-menu {
  width: 100%;

  .el-menu-item,
  .el-submenu__title {
    height: 40px !important;
    line-height: 40px !important;
  }

  li {
    width: 100%;
    text-align: left;
  }
}
</style>
