// let requestList = new Set() // 存储请求url
import axios from "axios";
import { Message } from 'element-ui';
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, //添加于请求路径前
  timeout: 5000,
});
service.interceptors.request.use(
  //请求拦截
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Content-Type"] = 'application/json';
      config.headers["X-Requested-With"] = 'XMLHttpRequest';
      config.headers["SESSION-TOKEN"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  //对请求回来的数据做统一错误处理
  (response) => {
    // requestList.delete(response.config.url)
    const res = response;
    if (res.status !== 200) {
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    // return Promise.reject(error);
    // 这里判断异常情况，如果axios.isCancel 为 true时，说明请求被取消
    if (axios.isCancel(error)) {
      // 请求取消
      console.warn(error)
      console.table([error.message.split('---')[0]], 'cancel')
    } else {
      console.log("eroor",error.response.status);
      // 请求失败
      // if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      //   this.$message.warning('请求超时')
      // } else if (error.message == 'Network Error') {
      //   this.$message.error('网络连接异常，请重试')
      // } else {
      //   this.$message.error('未知错误', error.message)
      // }
      if (error.response.status !==200) {
        Message.closeAll()
        Message.error(error.message)
      }
      // 请求如果失败了，务必从列表里面删掉，否则请求拦截器会取消请求
      // requestList.delete(error.config.url)
    }
    return Promise.reject(error);
  }
);

export default service;
